import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import {
  Section,
  SimpleCallToActionBanner,
  NeedHelp,
  Button
} from '../components'
import * as styles from '../styles/pages/docs'
import variables from '../styles/variables'
import system10 from '../../assets/system10.svg'
import lightbulb from '../../assets/lightbulb.svg'
import folder from '../../assets/folder.svg'
import howTo from '../../assets/how-to.svg'
import heart from '../../assets/heart-pink-green.svg'
import explain from '../../assets/explain-pink-green.svg'
import slack from '../../assets/slack-pink-green.svg'
import reddit from '../../assets/reddit-pink-green.svg'
import pythonWhite from '../../assets/python-white.svg'
import aitoWhite from '../../assets/aito-white.svg'
import uipathDark from '../../assets/uipath-dark.svg'
import pythonDark from '../../assets/python-dark.svg'
import aitoDark from '../../assets/aito-dark.svg'
import apiColor from '../../assets/api-color.svg'
import automationAnywhere from '../../assets/automation-anywhere.svg'
import blueprism from '../../assets/blueprism.svg'
import robocorp from '../../assets/robocorp.svg'
import integromat from '../../assets/integromat.svg'
import consoleDark from '../../assets/aito-console-black.svg'
import consoleWhite from '../../assets/aito-console-white.svg'
import apiWhite from '../../assets/api-white.svg'
import apiDark from '../../assets/api-black.svg'
import airtable from '../../assets/airtable-color.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function DevelopersPage({ data }) {
  const { allYaml } = data
  const { node } = _.first(allYaml.edges)

  const sectionIcons = [lightbulb, folder, howTo]
  const supportIcons = [heart, explain, reddit]
  const getStartedIconsWhite = [aitoWhite, consoleWhite, pythonWhite, apiWhite]
  const getStartedIconDark = [aitoDark, consoleDark, pythonDark, apiDark]
  const integrationIcons = [
    airtable, automationAnywhere, blueprism, uipathDark, robocorp, integromat
  ]

  const [itemHover, setItemHover] = useState(null)

  const onItemMouseEnter = (index) => {
    setItemHover(index)
  }

  const onItemMouseLeave = (index) => {
    if (itemHover === index) {
      setItemHover(null)
    }
  }

  const firstChild = (
    <styles.IntroContainer>
      <styles.TextWrapper>
        <styles.CenteredHeader>
          {node.header}
        </styles.CenteredHeader>
        <styles.CenteredHeaderInfo>
          {node.headerInfo}
        </styles.CenteredHeaderInfo>
        <styles.ButtonContainer>
          {_.map(node.headerButtons, (button, i) => {
            return (
              <a href={button.url} target="_blank" rel="noopener noreferrer" key={i}>
                <styles.HeaderButton
                  type="outline"
                  buttonColor={variables.colors.silverTree.medium}
                  textColor={variables.colors.silverTree.medium}
                  size="small"
                >
                  {button.text}
                </styles.HeaderButton>
              </a>
            )
          })}
        </styles.ButtonContainer>
      </styles.TextWrapper>
      <styles.IntroSectionSideSquare src={system10} alt="" />
      <styles.IntroSectionBottomSquare src={system10} alt="" />
    </styles.IntroContainer>
  )

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <styles.SectionContainer>
            <styles.DocSectionContainer>
              <styles.DocSectionHead>
                <styles.DocSectionImg src={apiColor} alt="" />
                <styles.DocSectionInfo>
                  <styles.DocSectionTitle>{node.integrationSection.title}</styles.DocSectionTitle>
                  <styles.DocSectionText>{node.integrationSection.info}</styles.DocSectionText>
                </styles.DocSectionInfo>
              </styles.DocSectionHead>
              <styles.ItemRow>
                {_.map(node.integrationSection.items, (item, i) => {
                  return (
                    <Section key={i}>
                      <styles.ItemCol md={4} sm={6} xs={12}>
                        <a href={item} target="_blank" rel="noopener noreferrer">
                          <styles.IntegrationCardContainer>
                            <styles.IntegrationImage src={integrationIcons[i]} alt="" />
                            <styles.LearnMore>
                              <div className="learn-more">
                                Read more
                                <styles.Chevron />
                              </div>
                            </styles.LearnMore>
                          </styles.IntegrationCardContainer>
                        </a>
                      </styles.ItemCol>
                    </Section>
                  )
                })}
              </styles.ItemRow>
              <a href={node.integrationSection.seeAllLink.url} target="_blank" rel="noopener noreferrer">
                {node.integrationSection.seeAllLink.text}
              </a>
            </styles.DocSectionContainer>

            {_.map(node.docSection, (section, i) => {
              return (
                <styles.DocSectionContainer key={i}>
                  <styles.DocSectionHead>
                    <styles.DocSectionImg src={sectionIcons[i]} alt="" />
                    <styles.DocSectionInfo>
                      <styles.DocSectionTitle>{section.title}</styles.DocSectionTitle>
                      <styles.DocSectionText>{section.info}</styles.DocSectionText>
                    </styles.DocSectionInfo>
                  </styles.DocSectionHead>
                  <styles.ItemRow>
                    {_.map(section.items, (item, index) => {
                      const isItemHovered = itemHover === index
                      const onHoverCallbackProps = {
                        onMouseEnter: () => onItemMouseEnter(index),
                        onMouseLeave: () => onItemMouseLeave(index),
                      }
                      return (
                        <Section key={index}>
                          <styles.ItemCol sm={3} xs={12}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                              {
                                section.id === 'getStarted'
                                  ? (
                                    <styles.DocSectionItemContainer {...onHoverCallbackProps}>
                                      <img
                                        src={
                                          isItemHovered
                                            ? getStartedIconDark[index]
                                            : getStartedIconsWhite[index]
                                        }
                                        height={30}
                                        alt=" "
                                      />
                                      <p>{item.text}</p>
                                    </styles.DocSectionItemContainer>
                                  )
                                  : (
                                    <styles.DocSectionItemContainer>
                                      <styles.DocItemTitle>{item.title}</styles.DocItemTitle>
                                      <p>{item.text}</p>
                                    </styles.DocSectionItemContainer>
                                  )
                              }
                            </a>
                          </styles.ItemCol>
                        </Section>
                      )
                    })}
                  </styles.ItemRow>
                  <a href={section.seeAllLink.url} target="_blank" rel="noopener noreferrer">
                    {section.seeAllLink.text}
                  </a>
                </styles.DocSectionContainer>
              )
            })}

            <styles.MainCTA>
              <div>
                <styles.MainCTAHeader>{node.mainCTAHeader}</styles.MainCTAHeader>
                <styles.MainCTAText>{node.mainCTAText}</styles.MainCTAText>
              </div>
              <a href={node.mainCTAButton.url} target="_blank" rel="noopener noreferrer">
                <Button size="small">{node.mainCTAButton.text}</Button>
              </a>
            </styles.MainCTA>

            <styles.SupportSectionContainer>
              <styles.SupportSectionHeader>{node.supportHeader}</styles.SupportSectionHeader>
              <styles.ItemRow>
                {_.map(node.supportItems, (item, i) => {
                  return (
                    <Section key={i}>
                      <styles.ItemCol sm={4} xs={12}>
                        <Link to={item.url}>
                          <styles.SupportCard>
                            <img src={supportIcons[i]} alt="" />
                            <styles.SupportCardTitle>{item.title}</styles.SupportCardTitle>
                            <styles.SupportCardText>{item.text}</styles.SupportCardText>
                          </styles.SupportCard>
                        </Link>
                      </styles.ItemCol>
                    </Section>
                  )
                })}
              </styles.ItemRow>
            </styles.SupportSectionContainer>

            <NeedHelp />
          </styles.SectionContainer>
          <SimpleCallToActionBanner />
        </styles.Container>
      </Section>
    </PageLayout>
  )
}

DevelopersPage.propTypes = propTypes

export const contentQuery = graphql`
query GetDevelopersContent {
  allMdx(filter: {
    frontmatter: {
      path: { regex: "^/docs/articles/" }
    }
  }) {
    edges {
      node {
        frontmatter {
          path
          title
          date
          topicCategory
          publish
        }
      }
    }
  },

  allYaml(filter: {file: {eq: "docs"}}) {
    edges {
      node {
        title
        header
        headerInfo
        metaDescription

        headerButtons {
          text
          url
        }

        integrationSection {
          id
          title
          info
          items
          seeAllLink {
            text
            url
          }
        }

        docSection {
          id
          title
          info
          seeAllLink {
            text
            url
          }
          items {
            title
            text
            url
          }
        }

        mainCTAHeader
        mainCTAText
        mainCTAButton {
          text
          url
        }

        supportHeader
        supportItems {
          title
          text
          url
        }

      }
    }
  }
}
`
